import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
// reactstrap components
import { Button, Container, Row, Col, Carousel, CarouselItem, CarouselIndicators } from "reactstrap";

// core components
const itemsEsp = [
  {
    src: "url(" + require("assets/images/header/banner1.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="text-left" md="6">
            <h1 className="title">Condominio</h1>
            <h5>
              Único proyecto en exclusiva área protegida de imponente belleza natural, 
              ubicado en la Patagonia Norte de Chile, 
              destino turístico rodeado de parques nacionales, 
              con acceso directo a la Carretera Austral / Ruta 7.
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                to="/#condominio"
                tag={Link}
                size="lg"
              >
                Leer Más
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src: "url(" + require("assets/images/header/banner2.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title">Santa Bárbara | Chaitén</h1>
            <h5>
              El proyecto está orientado hacia quienes buscan la conservación y 
              el turismo de naturaleza, ofreciendo un refugio al sur del mundo.
            </h5>
            <br />
            <h6>Contactanos:</h6>
            <div className="buttons">
              {/* <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-facebook-square" />
              </Button> */}
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="http://instagram.com/fundo_santabarbara/"
                target="_blank"
                
              >
                <i className="fa fa-instagram" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src: "url(" + require("assets/images/header/banner3.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto text-right" md="7">
            <h2 className="title">Zona Segura</h2>
            <h5>
              Ubicados en zona segura geológica e inserto en un bosque siempreverde, 
              con un clima templado marítimo lluvioso lo que confiere fuentes hídricas abundantes y de extrema pureza.
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="success"
                to="/#contacto-us"
                tag={Link}
                size="lg"
              >
                <i className="fa fa-phone" />
                Contactanos
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
];
const itemsEn = [
  {
    src: "url(" + require("assets/images/header/banner1.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="text-left" md="6">
            <h1 className="title">Land Development</h1>
            <h5>
              This unique and exclusive project is located in the Northern
              Patagonia of Chile. One of a kind in its natural beauty and
              surrounded by national parks, with direct access to the Austral
              Highway / Route 7.
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                to="/#condominio"
                tag={Link}
                size="lg"
              >
                Read more
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src: "url(" + require("assets/images/header/banner2.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title">Santa Bárbara | Chaitén</h1>
            <h5>
              The project is oriented towards those seeking nature
              conservation and tourism, offering a refuge location at the very
              end of the world.
            </h5>
            <br />
            <h6>Contact us:</h6>
            <div className="buttons">
              {/* <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-facebook-square" />
              </Button> */}
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="http://instagram.com/fundo_santabarbara/"
                target="_blank"
                
              >
                <i className="fa fa-instagram" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
  {
    src: "url(" + require("assets/images/header/banner3.jpg") + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto text-right" md="7">
            <h2 className="title">Environmentally protected area</h2>
            <h5>
              The terrane is located in an environmentally protected area,
              inserted in an Evergreen forest. Its rainy climate provides many
              abundant, pure water sources.
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="success"
                to="/#contacto-us"
                tag={Link}
                size="lg"
              >
                <i className="fa fa-phone" />
                Contact us
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
  },
];

function HeaderApp(props) {
  const [textosHeader, setTextoMenu] = useState(itemsEsp)
  
  useEffect(() => {
    const updateTexto = () => {
      if(props.idioma === 'esp'){
        setTextoMenu(itemsEsp)
      }else{
        setTextoMenu(itemsEn)
      }
    }
    updateTexto()
  },[props.idioma])

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === textosHeader.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? textosHeader.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  
  return (
    <>
      <div id="headers">
        <div className="header-3">
          <div className="page-carousel">
            <div className="filter" />
            <Carousel interval="7000" activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={textosHeader}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {textosHeader.map((item) => {
                return (
                  <CarouselItem
                    
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div
                      className="page-header"
                      style={{ backgroundImage: item.src }}
                    >
                      <div className="filter" />
                      <div className="content-center">{item.content}</div>
                    </div>
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </div>
        </div>
        </div>
    </>
  );
}

export default HeaderApp;
