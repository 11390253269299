import React from "react";
import { Container, Row, Col } from 'reactstrap'
function Links() {
  return (
    <div className="section section-gray">
      <div className="features-1" id="links">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="8">
              <h2 className="title text-center">Links</h2>
              <ul>
                  <li className="pl-3"><a href="https://www.facebook.com/1889099497802604/posts/3487387807973757/">Ruta de los parques de la patagonia</a></li>
                  <li className="pl-3"><a href="https://www.facebook.com/110142727107440/posts/280296860092025/?vh=e&d=n">Chaiten Noticias</a></li>
                  <li className="pl-3"><a href="https://laderasur.com/articulo/marsupiales-chilenos-conoce-estos-pequenos-primitivos-y-excepcionales-mamiferos/">Ladera Sur - Marsupiales Chilenos</a></li>
                  <li className="pl-3"><a href="https://www.revistaenfoque.cl/chaiten-tierra-escondida">Revista Enfoque - Chaiten tierra escondida</a></li>
                  <li className="pl-3"><a href="https://edition.cnn.com/2020/07/15/world/vreni-haussermann-c2e-spc-scn-int/index.html">CNN - The wild deep: Discovering new species in Chilean Patagonia </a></li>
                  <li className="pl-3"><a href="https://www.facebook.com/ChaitenCl/videos/2760919497464339/?vh=e">Chaiten Noticias</a></li>
                  <li className="pl-3"><a href="https://www.facebook.com/vivechaiten/videos/2339403913031947/?vh=e">Miradas Chaiten</a></li>
                  <li className="pl-3"><a href="https://www.facebook.com/diarioelhuemul/videos/973331399770213/?vh=e">Cuesta Queulat</a></li>
                  <li className="pl-3"><a href="https://www.biobiochile.cl/especial/aqui-tierra/noticias/2020/07/08/conaf-encuentra-huillines-parque-nacional-pumalin-especie-esta-peligro-extincion.shtml
">Biobio noticias</a></li>
                  <li className="pl-3"><a href="https://www.escalando.org/2018/10/equipo-chileno-logra-primer-ascenso-a-la-cara-este-del-corcovado-2300-m/">Escalando.org</a></li>
                  <li className="pl-3"><a href="https://www.tripadvisor.cl/Attractions-g317796-Activities-Chaiten_Los_Lagos_Region.html">Tripadvisor</a></li>
                  <li className="pl-3"><a href="https://www.profesorenlinea.cl/Chilegeografia/Chaiten_Volcan.htm">Profesor en linea</a></li>
                  <li className="pl-3"><a href="https://youtu.be/Ux4fvcb7gp4">Youtube - El Golfo Corcovado: ecosistema único para ballenas azules en Chile</a></li>
                  <li className="pl-3"><a href="https://youtu.be/eyPIeZ6x4j4">Youtube - AUSTRALEX Kayak. Rio Chiñihue.Chaitén</a></li>
                  <li className="pl-3"><a href="https://youtu.be/TVZ2riVVppk">Youtube - Kayak Chili - Chaiten - Salto Pumalin</a></li>
                  <li className="pl-3"><a href="https://www.youtube.com/watch?v=0ag94doNih0">Youtube - Lugares que Hablan / Futaleufú, Naturaleza Imponente</a></li>
                  <li className="pl-3"><a href="https://www.13.cl/programas/lugares-que-hablan/temporada-2017/lugares-que-hablan-chaiten-naturaleza-indomita">C13 - Lugares que hablan</a></li>
                  <li className="pl-3"><a href="https://www.youtube.com/watch?v=6_OFXBK37gs">Youtube - DW - Rumbo al corazón de la Patagonia | DW Documental</a> </li>
                  <li className="pl-3"><a href="https://www.youtube.com/watch?v=sPxoZ0bLgVg">Youtube - Capítulo 5 | Lugares Que Hablan | Temporada 2018</a></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default Links

