import React from "react";
import { Container, Row, Col } from "reactstrap";
const ubicacionTextos = [
  {
    ubicacionEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Ubicación</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className="ubicacion-container">
              <iframe
                className="ubicacion-iframe"
                title="ubicacion"
                src="https://www.google.com/maps/d/u/0/embed?mid=1LhdI2Mj6OlXAi8gS5nZtPU_oLA5WPHXS"
              ></iframe>
            </div>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal pt-0 pt-sm-4">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Al Norte</h4>
                <ul className="pl-0">
                  <li>Playa Santa Bárbara: 1,5 km</li>
                  <li>Cerro El Vilcún (cuevas con pinturas rupestres): 5 Km</li>
                  <li>Aeropuerto: 10 min</li>
                  <li>Sendero Alerces Milenarios (Parque Pumalín): 35 min</li>
                  <li>
                    Caleta Gonzalo (Fiordo Reñihue, transbordador ruta bimodal):
                    50 min
                  </li>
                </ul>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description mr-0">
                <h4 className="info-title">Al Sur</h4>
                <ul className="pl-0">
                  <li>Chaitén a 7,5 Kms directo por ruta 7: 8 min </li>
                  <li>El Amarillo (Parque Pumalín)</li>
                  <li>Sendero Volcán Michimahuida: 30 min</li>
                  <li>Termas El Amarillo: 45 min</li>
                  <li>Lago Yelcho: 55 min</li>
                  <li>Futaleufú – Río Futaleufú: 3 hrs</li>
                  <li>Puyuhuapi (termas, parque nacional Queulat): 5 hrs</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  },
  {
    ubicacionEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Location</h2>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className="ubicacion-container">
              <iframe
                className="ubicacion-iframe"
                title="ubicacion"
                src="https://www.google.com/maps/d/u/0/embed?mid=1LhdI2Mj6OlXAi8gS5nZtPU_oLA5WPHXS"
              ></iframe>
            </div>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal pt-0 pt-sm-4">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description">
                <h4 className="info-title">To the north</h4>
                <ul className="pl-0">
                  <li>Santa Barbara Beach - 0.9 mi / 1.5 km</li>
                  <li>Cerro El Vilcún (caves with cave paintings): 5 Km</li>
                  <li>Airport: 10 min</li>
                  <li>Millennium Alerces Trail (Pumalín Park): 35 min</li>
                  <li>
                    Caleta Gonzalo (Reñihue Fjord, bimodal route ferry): 50
                    min
                  </li>
                </ul>
              </div>
            </div>
            <div className="info info-horizontal">
              <div className="icon icon-success">
                <i aria-hidden={true} className="nc-icon icon-map-pin-2" />
              </div>
              <div className="description">
                <h4 className="info-title">To the south</h4>
                <ul className="pl-0">
                  <li>Chaitén at 7.5 Kms direct by route 7: 8 min </li>
                  <li>El Amarillo (Pumalín Park)</li>
                  <li>Michimahuida Volcano Trail: 30 min</li>
                  <li>El Amarillo Hot Springs: 45 min</li>
                  <li>Yelcho Lake: 55 min</li>
                  <li>Futaleufú - Futaleufú River: 3 hrs</li>
                  <li>Puyuhuapi (hot springs, Queulat national park): 5 hrs</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
];
function Ubicacion(props) {
  const renderUbicacion = () => {
    if (props.idioma === "esp") {
      return (
        ubicacionTextos[0].ubicacionEs
      )
    } else{
      return (
        ubicacionTextos[1].ubicacionEn
      )
    }
  };
  return (
    <>
      <div className="section section-feature cd-section" id="ubicacion">
        <div className="features-4">{renderUbicacion()}</div>
      </div>
    </>
  );
}

export default Ubicacion;
