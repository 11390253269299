import React from "react";
// reactstrap components
import { CardContacto, Nombre, Telefono } from "./styles";
import Chile from "../Icons/Chile";
import Usa from "../Icons/Usa";
import WhatsApp from "../Icons/WhatsApp";
import Phone from "../Icons/Phone";
import Formulario from "./Formulario.js";
import { Card, CardTitle, Container, Row, Col } from "reactstrap";

const contactoContenido = [
  {
    contactoEs: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <Card className="card-contact no-transition">
              <CardTitle className="text-center" tag="h3">
                Contactanos
              </CardTitle>
              <Row>
                <Col className="ml-auto" md="5">
                  <h4 className="info-title pl-3 pl-md-0">Llamanos</h4>
                  <CardContacto>
                    <Chile size="25" />
                    <Nombre>Christian Pablo Azócar Lara</Nombre>
                    <Telefono>
                      <Phone size="25" color="#1EBEA5" />
                      <a className="pl-2" href="tel:+56986903495">
                        +56 9 86903495
                      </a>
                    </Telefono>
                  </CardContacto>
                  <hr />
                  <CardContacto>
                    <Chile size="25" />
                    <Nombre>Pilar Santa María Valdés</Nombre>
                    <Telefono>
                      <WhatsApp size="25" color="#1EBEA5" />
                      <a
                        className="pl-2"
                        href="https://api.whatsapp.com/send?phone=56997995358"
                      >
                        +569 97995358
                      </a>
                    </Telefono>
                  </CardContacto>
                  <hr />
                  <CardContacto>
                    <Usa size="25" />
                    <Nombre>Carla Munin Saavedra</Nombre>
                    <Telefono>
                      <WhatsApp size="25" color="#1EBEA5" />
                      <a
                        className="pl-2"
                        href="https://api.whatsapp.com/send?phone=17144026370"
                      >
                        +1 714 4026370
                      </a>
                    </Telefono>
                  </CardContacto>
                </Col>
                <Col className="mr-auto" md="5">
                  <Formulario idioma="es" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    contactoEn: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <Card className="card-contact no-transition">
              <CardTitle className="text-center" tag="h3">
                Contact Us
              </CardTitle>
              <Row>
                <Col className="ml-auto" md="5">
                  <h4 className="info-title pl-3 pl-md-0">Call Us</h4>
                  <CardContacto>
                    <Chile size="25" />
                    <Nombre>Christian Pablo Azócar Lara</Nombre>
                    <Telefono>
                      <Phone size="25" color="#1EBEA5" />
                      <a className="pl-2" href="tel:+56986903495">
                        +56 9 86903495
                      </a>
                    </Telefono>
                  </CardContacto>
                  <hr />
                  <CardContacto>
                    <Chile size="25" />
                    <Nombre>Pilar Santa María Valdés</Nombre>
                    <Telefono>
                      <WhatsApp size="25" color="#1EBEA5" />
                      <a
                        className="pl-2"
                        href="https://api.whatsapp.com/send?phone=56997995358"
                      >
                        +569 97995358
                      </a>
                    </Telefono>
                  </CardContacto>
                  <hr />
                  <CardContacto>
                    <Usa size="25" />
                    <Nombre>Carla Munin Saavedra</Nombre>
                    <Telefono>
                      <WhatsApp size="25" color="#1EBEA5" />
                      <a
                        className="pl-2"
                        href="https://api.whatsapp.com/send?phone=17144026370"
                      >
                        +1 714 4026370
                      </a>
                    </Telefono>
                  </CardContacto>
                </Col>
                <Col className="mr-auto" md="5">
                  <Formulario idioma="en" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    ),
  },
];
function SectionContactUs(props) {
  const renderContacto = () => {
    if (props.idioma === "esp") {
      return contactoContenido[0].contactoEs;
    } else {
      return contactoContenido[1].contactoEn;
    }
  };
  return (
    <>
      <div className="section section-contactus cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/neill-kumar.jpg") + ")",
          }}
        >
          {renderContacto()}
        </div>
      </div>
    </>
  );
}

export default SectionContactUs;
